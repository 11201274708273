import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticQuery, graphql } from "gatsby"
import { PhotoSwipeGallery } from "react-photoswipe"
import "react-photoswipe/lib/photoswipe.css"
import "./menu.scss"
const SecondPage = () => (
  <Layout>
    <SEO title="Listino pizze" />
    <MenuPages />
  </Layout>
)

export default SecondPage

const MenuPages = props => {
  let options = {
    //http://photoswipe.com/documentation/options.html
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          menu1: file(relativePath: { eq: "menu-pagina-1.jpeg" }) {
            publicURL
            relativePath
          }
          menu2: file(relativePath: { eq: "menu-pagina-2.jpeg" }) {
            publicURL
            relativePath
          }
          menuThumb1: file(relativePath: { eq: "thumb-menu-pagina-1.jpeg" }) {
            publicURL
            relativePath
          }
          menuThumb2: file(relativePath: { eq: "thumb-menu-pagina-2.jpeg" }) {
            publicURL
            relativePath
          }
        }
      `}
      render={data => (
        <div>
          <h1>Listino pizze</h1>
          <p>
            Con un ricco listino di pizze e fritti vari, qui da{" "}
            <strong>The Italian Job</strong> possiamo accontentare i palati di
            tutti
          </p>
          <PhotoSwipeGallery
            items={[
              {
                src: data.menu1.publicURL,
                thumbnail: data.menuThumb1.publicURL,
                w: 1200,
                h: 900,
                title: "Image 1",
              },
              {
                src: data.menu2.publicURL,
                thumbnail: data.menuThumb2.publicURL,
                w: 1200,
                h: 900,
                title: "Image 2",
              },
            ]}
            options={options}
            thumbnailContent={getThumbnailContent}
          />
        </div>
      )}
    />
  )
}

function getThumbnailContent(item) {
  return <img src={item.thumbnail} width={240} height={180} alt={item.title} />
}
